import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M1980 3529 c0 -86 4 -139 10 -139 6 0 10 15 10 33 0 46 31 98 72 119
32 17 65 18 432 16 l397 -3 -57 -90 c-31 -49 -158 -249 -282 -444 -124 -195
-227 -362 -229 -371 -6 -24 98 -104 113 -87 11 12 103 156 301 472 55 88 164
261 242 384 77 123 141 227 141 231 0 5 -17 3 -37 -3 -27 -9 -179 -12 -513
-12 -405 0 -484 2 -537 16 l-63 16 0 -138z"/>
<path d="M1840 2578 c-26 -28 -26 -68 1 -102 16 -20 29 -26 60 -26 50 0 79 27
79 75 0 48 -29 75 -80 75 -27 0 -45 -6 -60 -22z"/>
<path d="M2098 2287 c-82 -129 -148 -239 -148 -245 0 -7 8 -12 18 -12 57 0
245 -63 467 -156 6 -3 21 13 33 35 l23 40 -176 57 c-96 31 -175 58 -175 61 0
3 28 50 63 104 34 55 83 134 110 175 26 42 47 79 47 83 0 8 -99 91 -109 91 -3
0 -72 -105 -153 -233z"/>
<path d="M2582 1869 c-13 -23 -20 -45 -15 -49 11 -10 279 -109 408 -150 325
-104 629 -116 835 -35 61 25 167 93 218 142 44 41 56 71 25 58 -239 -94 -474
-124 -723 -90 -174 23 -461 88 -640 144 -36 12 -69 21 -75 21 -5 0 -21 -19
-33 -41z"/>
<path d="M970 1423 c0 -5 29 -6 65 -5 36 2 65 0 65 -3 0 -4 -29 -54 -65 -111
-36 -57 -65 -106 -65 -109 0 -3 38 -5 85 -5 47 0 85 4 85 8 0 4 -31 6 -70 4
-38 -2 -70 -2 -70 1 0 3 29 53 65 110 36 58 65 107 65 111 0 3 -36 6 -80 6
-44 0 -80 -3 -80 -7z"/>
<path d="M1230 1300 c-24 -55 -48 -101 -54 -104 -6 -2 -2 -5 9 -5 15 -1 24 8
30 32 9 29 14 32 54 35 47 4 61 -6 61 -45 0 -16 6 -23 19 -23 18 0 17 6 -16
78 -19 42 -41 89 -47 104 -12 26 -13 24 -56 -72z m48 -28 c-41 -5 -44 2 -23
49 l19 41 17 -44 17 -43 -30 -3z"/>
<path d="M1465 1391 c-38 -16 -60 -51 -60 -97 0 -58 34 -94 96 -99 44 -4 44
-4 7 4 -59 12 -78 36 -78 93 0 39 5 54 25 73 28 29 79 33 113 9 13 -9 22 -11
22 -4 0 24 -83 38 -125 21z"/>
<path d="M1705 1391 c-33 -14 -56 -47 -62 -89 -4 -33 -1 -44 23 -70 16 -17 43
-34 59 -37 l30 -6 -30 14 c-38 18 -51 34 -59 74 -5 21 -2 42 8 62 40 76 127
66 164 -18 14 -31 17 -33 20 -14 10 62 -82 112 -153 84z"/>
<path d="M2215 1295 l0 -107 74 4 c65 3 76 6 97 31 17 20 24 40 24 72 0 32 -7
52 -24 72 -21 25 -32 28 -97 31 l-74 4 0 -107z m136 82 c23 -17 39 -52 39 -82
0 -61 -36 -94 -102 -95 l-48 0 0 95 0 95 48 0 c26 0 54 -6 63 -13z"/>
<path d="M2660 1392 c0 -4 18 -6 41 -4 l40 4 -3 -101 c-2 -85 -1 -101 12 -101
13 0 14 16 12 99 l-3 100 43 4 c24 2 1 4 -49 5 -51 1 -93 -1 -93 -6z"/>
<path d="M3110 1380 c-11 -11 -20 -25 -20 -31 0 -6 24 -33 53 -60 44 -41 50
-51 41 -66 -17 -28 -59 -28 -78 -1 -16 22 -16 22 -16 1 0 -42 94 -42 109 0 12
33 4 49 -45 86 -24 18 -44 39 -44 46 0 21 28 38 55 33 14 -3 25 -1 25 3 0 17
-60 9 -80 -11z"/>
<path d="M3260 1322 c0 -54 5 -84 16 -100 21 -30 38 -28 18 2 -12 19 -15 44
-12 101 2 55 0 75 -9 75 -9 0 -13 -22 -13 -78z"/>
<path d="M3410 1322 c0 -89 -10 -110 -60 -123 l-35 -9 39 5 c22 2 46 11 54 19
18 18 30 186 13 186 -7 0 -11 -26 -11 -78z"/>
<path d="M3495 1295 l0 -105 51 0 c37 0 56 5 67 18 23 26 21 68 -4 85 -13 9
-16 17 -10 22 6 3 11 19 11 35 0 35 -23 50 -77 50 l-38 0 0 -105z m93 58 c3
-25 -1 -33 -19 -38 -21 -7 -21 -8 9 -21 25 -10 32 -19 32 -42 0 -37 -16 -52
-57 -52 l-33 0 0 96 0 95 33 -3 c28 -3 32 -7 35 -35z"/>
<path d="M1936 1363 c-13 -70 -18 -117 -10 -110 4 4 10 26 14 50 l6 42 38 -74
c24 -46 41 -70 47 -64 6 6 -7 40 -37 94 -25 46 -48 85 -49 87 -2 2 -6 -9 -9
-25z"/>
<path d="M2085 1338 c-15 -29 -31 -63 -36 -75 -15 -41 0 -24 28 30 l28 52 9
-77 c8 -65 12 -78 27 -78 15 0 17 4 9 23 -5 12 -14 56 -20 97 -6 41 -12 76
-14 77 -2 2 -15 -20 -31 -49z"/>
<path d="M2493 1293 c-40 -84 -46 -103 -32 -103 11 0 19 12 23 36 7 35 7 35
54 32 43 -3 48 -5 57 -35 6 -21 16 -33 26 -33 15 0 13 10 -15 68 -17 37 -39
83 -48 102 l-16 35 -49 -102z m66 26 c16 -46 15 -49 -23 -49 l-34 0 15 40 c9
22 19 40 24 40 4 0 12 -14 18 -31z"/>
<path d="M2944 1368 c-4 -13 -24 -58 -44 -100 -35 -73 -36 -78 -17 -78 11 0
18 3 14 6 -3 3 -1 19 5 35 9 26 14 29 54 29 39 0 46 -3 54 -26 6 -14 8 -30 4
-35 -3 -5 5 -9 18 -9 24 0 23 1 -19 88 -23 48 -42 93 -42 100 -1 20 -19 14
-27 -10z m25 -30 c25 -68 25 -68 -14 -68 -31 0 -36 3 -30 18 4 9 12 29 17 45
12 33 16 34 27 5z"/>
<path d="M1571 1219 c-13 -10 -19 -19 -14 -19 13 0 49 27 43 33 -3 3 -16 -3
-29 -14z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
